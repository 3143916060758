import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import submitForm from '../utils/submitForm';
import ConfIcon from '../assets/images/newsletter-confirmation.svg';
// import media from '../utils/media';
import styled from 'styled-components';
import tw from 'twin.macro';

const NewsletterForm = styled.div`
  form {
    ${tw`flex`}
    min-height: 49px;

    @media screen and (max-width:600px) {
      ${tw`flex-col`}
    }
  }

  label {
    ${tw`flex-auto`}
    border:1px solid white;
    margin-right: 0.5rem;

    @media screen and (max-width:600px) {
      margin-right:0;
    }

    &.is-invalid {
      border-color: red;
    }
  }

  input {
    /* height: 100%; */
    padding-left: 0.75rem;
    background-color: transparent;
    font-size: 0.8rem;
    height: 49px;
  }

  button {
    font-size: 16px;
    font-weight: 500;
    flex: 1 1 auto;
    height: 51px;
    max-width:108px;

    @media screen and (max-width:600px) {
      max-width:100%;
    }
  }
`;

const Modal = styled.div`
  ${tw`fixed inset-0 flex flex-col items-center justify-center z-50`}
  background-color:rgba(0,0,0,.3);
`;


export default ({confirmation}) => {
  const [isSent, setIsSent] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (isSent === true) {
      setModalOpen(true);
    }
  }, [isSent]);

  const { register, handleSubmit, errors } = useForm({});

  const onSubmit = async (fields) => {
    const { status } = await submitForm({
      formId: '43b9e67c-3c8e-4f1c-95d3-2c4e18b21c71',
      fields,
    });
    if (status === 200) {
      setIsSent(true);
      return;
    }
  };

  return (
    <div>
      <NewsletterForm>
        <form className={` ${isSent ? 'pointer-events-none select-none' : ''}`} onSubmit={handleSubmit(onSubmit)}>
          <label className={` block ${errors.email && 'is-invalid'}`}>
            <input
              placeholder="Enter your email"
              type="email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
            />
          </label>

          <button type="submit" className=" px-6 h-full bg-purple text-white hover:bg-white hover:text-black transition duration-100">
            Submit
          </button>
        </form>
      </NewsletterForm>
      {isSent && modalOpen && (
        <Modal>
          <div className="container">
            <div className="p-10 bg-white text-black max-w-xl mx-auto text-center relative">
              <div className="cursor-pointer absolute top-0 right-0 p-4" onClick={() => setModalOpen(false)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.30127 1.01904L18.7874 18.5052" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M18.7874 1.01904L1.30127 18.5052" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              <div className="pt-3 pb-4">
                <img src={ConfIcon} alt="" className="mx-auto" />
                <h2 className="text-42px my-5" dangerouslySetInnerHTML={{ __html: confirmation.heading }}/>
                <p className="text-16px max-w-md mx-auto" style={{ lineHeight: '1.7' }} dangerouslySetInnerHTML={{ __html: confirmation.content }} />
                <div className="mt-8 font-medium" dangerouslySetInnerHTML={{ __html: confirmation.bottomHeading }} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
