import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import SEO from '../components/SEO';
import BlogPreview from '../components/BlogPreview';
import NewsletterSubscribeSection from '../components/NewsletterSubscribeSection';
import FooterCTA from '../components/FooterCTA';
import styled from 'styled-components';
import tw from 'twin.macro';
import media from '../utils/media';

const Cat = styled(Link)`
  ${tw`block bg-black text-white text-16px font-medium text-center py-2 px-4 mx-2 transition duration-150`}
  &:hover,
  &.is-active {
    ${tw`bg-purple`}
  }
  ${media.greaterThan('lg')`
    ${tw`py-3`}
  `}
`;

export default class Blog extends Component {
  render() {
    const { seo } = this.props.data.wpPage;
    const { nodes: allPosts } = this.props.data.allWpPost;
    const { nodes: featured } = this.props.data.featured;
    const { nodes: cats } = this.props.data.allWpCategory;
    const newsletterSubscribeFields = this.props.data.wp.options.options.newsletterSubscribe;

    const { numberOfPages, pageNumber = 0, isCategory, categoryName = '' } = this.props.pageContext;
    const posts = !isCategory ? allPosts : allPosts.filter((p) => p.categories.nodes.map(({ name }) => name).includes(categoryName));

    return (
      <Layout>
        <SEO seo={seo} />

        <section className="pad-b--xxs pad-t--xs lg:pad-t sm:pad-b--xs">
          <div className="container">
            <div className="hidden md:flex flex-wrap xl:flex-no-wrap justify-center flex-col sm:flex-row -mb-4">
              <div className="pb-4 flex-auto">
                <Cat to="/blog/" className={!isCategory ? 'is-active' : ''} activeClassName="is-active" dangerouslySetInnerHTML={{ __html: 'All' }} />
              </div>
              {cats.map((cat) => (
                <div key={cat.id} className="pb-4  flex-auto">
                  <Cat to={cat.uri} activeClassName="is-active" dangerouslySetInnerHTML={{ __html: cat.name }} />
                </div>
              ))}
            </div>

            {/* Mobile */}
            <div className="md:hidden">
              <select
                onChange={(e) => {
                  return navigate(e.target.value);
                }}
                value={this.props.path}
                className="appearance-none font-medium border-b"
              >
                <option value="/blog/">All Categories</option>
                {cats.map((cat) => (
                  <option key={`mobile-${cat.id}`} value={cat.uri} dangerouslySetInnerHTML={{ __html: cat.name }} />
                ))}
              </select>
            </div>
          </div>
        </section>

        {!isCategory && featured.length >= 2 && pageNumber === 0 && (
          <>
            <section>
              <div className="container grid sm:grid-cols-2 col-gap-6 row-gap-12">
                {featured.map((post) => (
                  <BlogPreview key={post.id} post={post} />
                ))}
              </div>
            </section>

            <section className="pad-tb--xs -mx-5">
              <div className="container">
                <FooterCTA />
              </div>
            </section>
          </>
        )}

        <section className="posts pad-b--sm">
          <div className="container">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 col-gap-6 row-gap-12">
              {posts.slice(0, 3).map((post) => (
                <BlogPreview key={post.id} post={post} />
              ))}
            </div>

            <div className="my-20">
              <NewsletterSubscribeSection
                {...newsletterSubscribeFields}
              />
            </div>

            <div className="grid lg:grid-cols-3 sm:grid-cols-2 col-gap-6 row-gap-12">
              {posts.slice(3).map((post) => (
                <BlogPreview key={post.id} post={post} />
              ))}
            </div>
          </div>
        </section>

        {numberOfPages > 1 && (
          <section className="pad-b--xxs">
            <div className="container flex justify-center">
              {[...Array(numberOfPages)].map((un, i) => {
                const num = i + 1;
                return (
                  <div key={`pagi-${i}`} className="mx-1">
                    <Link to={`/blog/${num > 1 ? num : ''}`} className="o-ratio bg-black text-white font-semibold" activeClassName="bg-purple" style={{ width: '35px' }}>
                      <div className="o-ratio__content flex items-center justify-center">{i + 1}</div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </section>
        )}
      </Layout>
    );
  }
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    featured: allWpPost(sort: { fields: date, order: ASC }, filter: { blogPost: { featurePost: { eq: true } } }) {
      nodes {
        ...Post
      }
    }

    allWpPost(sort: { fields: date, order: DESC }, skip: $skip, limit: $limit) {
      nodes {
        ...Post
      }
    }
    wpPage(slug: { eq: "blog" }) {
      ...SEO
    }

    allWpCategory(filter: { count: { gt: 0 }, name: { ne: "Uncategorised" } }) {
      nodes {
        id
        name
        uri
      }
    }
    wp {
      options {
        options {
          newsletterSubscribe {
            heading
            bottomHeading
            confirmation {
              heading
              content
              bottomHeading
            }
          }
        }
      }
    }
  }
`;
